
.slidify {
  .slidify__nav { z-index: 500;position: relative; position: absolute; top: 500px; }
  .slidify__nav-prev, .slidify__nav-next {
    background: white;
    border-radius: 100px;
    width: 46px;
    height:46px;
    position: absolute;
    padding: 11px 17px;
    left: 75px;
    &:after {
      content: '';
      display: block;
      background: url('../images/gallery-nav-prev.svg') no-repeat;
      width: 12px;
      height: 24px;
    }
  }
  .slidify__nav-next {
    left: 135px;
    &:after {
      background: url('../images/gallery-nav-next.svg') no-repeat;
    }
  }
  .slidify__elt {
    overflow:hidden;opacity: 1; position: absolute; width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .slidify__elt.active { opacity: 1;}
}

.slidify__elt {left: -1200px;transition: left 1000ms linear;}
.slidify__elt.active {

  left: 0px;
}

.slidify .slidify__nav-prev:after, .slidify .slidify__nav-next:after {
  position: absolute;
  left: 17px;
  top: 12px;
  transition: left 100ms ease-in;
}
.slidify .slidify__nav-prev:hover:after {
  left: 12px;
}
.slidify .slidify__nav-next:hover:after {
  left: 22px;
}

.mt-a btn btn--white btn--round btn--white-purple btn--download:hover:after {
  transform: scaleX(1.1);
}

.slidify__slideshow {
  left: 0px;
  transition: left 400ms ease-in;
  position: absolute;
  height: 700px;
  width: 625px;
}