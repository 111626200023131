@import "./vars";
@import "../vendor/bootstrap/bootstrap";
@import "../vendor/animate/animate";
@import "ui/btn";
@import "ui/blocks";
@import "ui/atomic";
@import "ui/typography";
@import "ui/header";
@import "ui/hero";
@import "ui/footer";
@import "ui/huge-menu";
@import "ui/menu";
@import "ui/slidify";

body {
  color: $color__dark-gray;
  font-family: adelle;
  font-weight: 300;
  font-size: 15px;
  line-height: 27px;
  color: $color__dark-gray;
  position: relative;
  overflow-x: hidden;
}

.body--no-overflow {
  overflow-y: hidden;
}

.section-pad {
  padding: 250px 0;
}

.col-pad {
  padding: 0 80px;
}

.vpad {
  padding-top: 100px;
  padding-bottom: 100px;
}

.vpadt {
  padding-top: 100px;
}

.vpadb {
  padding-bottom: 100px;
}

.wf-loading {
  opacity: 0;
}
.js__gallery-mobile {display: none;}

.separator {
  margin: 0 auto;
  margin-bottom: 25px;
  width: 25px;
  background: $color__dark-gray;
  height: 3px;
}

// Wrapper
.wrapper {
  max-width: 1665px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

