.footer {
  background: url('../images/footer-image.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 100px 0;
  &:after {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    background: rgba(60,74,84,0.95);
    z-index: 1;
  }
}
.footer .container { z-index: 2; position: relative}
.footer-title {
  color: white;
  font-size: 26px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 20px;
  opacity: 0.5;
  font-family: acumin-pro-extra-condensed;
  margin-bottom: 15px;
  &:after {
    border-color: white;
    opacity: 0.5;
    margin-top: 15px;
    display: block;
    content: '';
    border-bottom: 2px solid white;
    text-transform: uppercase;
    width: 15px;
  }
}

.footer-menu-link {
  cursor: pointer;
  color: white;
  font-size: 14px;
  display: block;
  line-height: 23px;
  transition: color 100ms linear;
  &:hover {
    color: $color__red;
    text-decoration: none;
  }
  &:focus {
    color: $color__red;
    text-decoration: none;
  }
}

.footer-logo {
  background: url('../images/logo.png');
  background-repeat: no-repeat;
  height: 75px;	width: 81px;
  background-size: contain;
  position: absolute;
  right: 15px;
  bottom: 0;
  margin-left: -15px;
}

.footer-separator {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid white;
}
.footer-bot {
  color: white;
  line-height: 21px;
  font-size: 14px;
}

.footer-bar {
  font-size: 13px;
}
.footer-bar--red {
  background: $color__red;
  color: white;
}
.footer-bar__content {
  padding: 10px 0;
  &__partners {
    padding: 16px;
    &-label {
      font-size: 15px;
    }
    &-images {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      .partner-img {
        max-height: 72px;
        object-fit: contain;
        background-color: #fff;
        padding: 4px;
        margin: 4px 0;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}

.footer-bar__content p {
  display: inline;
}
.blcreative {
  position: absolute;
  right: 0;
  bottom: 0;
  font-family: raleway;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  vertical-align: middle;

  color: $color__dark-gray;
  &:hover {
    color: $color__dark-gray;
    text-decoration: none;
  }
}

.blcreative-logo {
  display: inline-block;
  background: $color__dark-gray;
  padding: 10px 30px;
  margin-left: 20px;
}

.footer-bar--white {
  background: white;
  color: $color__red;
  font-size: 13px;
  line-height: 32px;
  letter-spacing: 0.5px;
}
.footer-bar--white .footer-bar__content {
  line-height: 32px;
  font-weight: 600;
  p {
    display: inline;
  }
}

.footer-bar--yellow {
  background: $color__yellow;
  color: $color__dark-gray;
  font-size: 15px;
  line-height: 34px;
  text-transform: uppercase;
}

.footer-bar__phone {
  margin-bottom: 0px;
  float: left;
  background: $color__dark-gray;;
  color: $color__yellow;
  font-size: 25px;
  line-height: 34px;
  font-family: "acumin-pro-extra-condensed";
  &:hover {
    color: $color__yellow;
    text-transform: none;
    text-decoration: none;
  }
}

.footer-bar__btn {
  background: white;
  color: $color__dark-gray;
  font-size: 22px;
  margin-top: -10px;
  margin-bottom: 0;
  line-height: 22px;
  opacity: 1;
  &:after { top: 22px;}
}

.footer-bar__phone {
  padding: 10px 20px;
  margin-top: -10px;
  line-height: 34px;
  letter-spacing: 1px; font-weight: 400;
  img {
    vertical-align: baseline;
    margin-right: 10px;
  }
}
.footer__ico-fb, .footer__ico-map {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  opacity: 0.8;
  transition: opacity 100ms ease-in;
  &:hover {
    opacity: 1;
  }
}

.footer-ico { width: 30px; display: inline-block;}