@import "block-tile";
@import "block-overlap";

// BLOCK: Image fullwidth
.block-image-fullwidth {
  width: 100%;
  background-size: cover;
  padding: 175px 0;
}


// ---



// ---