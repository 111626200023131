
.block-tile {}
.block-tile .block-tile__body {
  min-height: 370px;
}

.block-tile__img {
  position: relative;
  height: 300px;
  background-size: cover;
  background-position: center center;
}
.block-tile__img img {
  width: 100%;
}
.block-tile__title:before {
  min-height: 110px;
  content:'';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  z-index: 1;
  bottom: 0;
}
.block-tile__title {
  font-family: "acumin-pro-extra-condensed";
  font-size: 40px;
  font-weight: 400;
  line-height: 35px;
  color: white;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.block-tile__title .block-tile__title__text {
  padding: 15px 50px;
  width: 100%;
  z-index: 2;
  position: relative;
}

.block-tile__body {
  padding: 50px;
}

.block-tile--dark {
  .block-tile__title:before {
    background: $color__dark-gray;
  }
  .block-tile__body {
    color: white;
    background: $color__dark-gray;
  }
}
.block-tile--red {
  .block-tile__title:before {
    background: $color__red;
  }
  .block-tile__body {
    color: white;
    background: $color__red;
  }
}
.block-tile--light {
  .block-tile__title:before {
    background: lightslategrey;//$color__light-gray;
  }
  .block-tile__body {
    color: $color__dark-gray;
    background: $color__light-gray;
  }
}
.block-tile--green {
  .block-tile__title:before {
    background: #7ED321;
  }
  .block-tile__body {
    color: white;
    background: #7ED321;
  }
}