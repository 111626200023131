// Block overlap
$overlap-size: 50px;
@mixin block-overlap-depth($num) {
  .block-overlap img { z-index: $num }
  .block-overlap__content:after {
    z-index: $num +1 ;
  }
  .block-overlap__content .block-overlap__body {
    z-index: $num + 2;
  }
}

.block-overlap__content:after {
  content: '';
  display: block;
  position: absolute;
  width: 1500px;
  height: calc(100% + 50px);
  z-index: 5;
  top: 0;
}

.block-overlap .block-overlap__row:nth-child(1) .block-overlap__content:after {
  height: calc(100% + 50px);// + 50px
}
.block-overlap__left .block-overlap__content:after {
  right: -$overlap-size;
}
.block-overlap__right .block-overlap__content:after {
  left: -$overlap-size;
}

.block-overlap--white, .block-overlap--white {
  color: $color__dark-gray;
}
.block-overlap--red {
  color: white;
}
.block-overlap--white .block-overlap__content:after {
  background: white;
}
.block-overlap--red .block-overlap__content:after {
  background: $color__red;
}
.block-overlap--grey .block-overlap__content:after {
  background: $color__light-gray;
}

.block-overlap__left .slidify__elt { position: absolute;}
.block-overlap__right .slidify__elt { position: absolute;}
.block-overlap__left .slidify__nav { right: 310px;}

.block-overlap__left .block-overlap__content {
  //padding: 20px;
  //margin-top: 50px;
}

.block-overlap__left .block-overlap__content {
  padding-right: 140px;
  padding-bottom: 50px;
}
.block-overlap__right .block-overlap__content {
  padding-left: 140px;
  padding-bottom: 50px;
}
.block-overlap__content p {
  margin-bottom: 40px;
}

.block-overlap__content .block-overlap__body {
  z-index: 10;
  padding-top: $overlap-size *2 ;
  position: relative;
}

.block-overlap__right {
  z-index: 1;
}


.block-overlap .block-overlap__row:nth-child(1) {
  @include block-overlap-depth(40);
}
.block-overlap .block-overlap__row:nth-child(2) {
  @include block-overlap-depth(30);
}
.block-overlap .block-overlap__row:nth-child(3) {
  @include block-overlap-depth(20);
}
.block-overlap .block-overlap__row:nth-child(4) {
  @include block-overlap-depth(10);
}

//
.block-overlap__left.block-overlap-gallery {
  left: -260px;
  right: auto;
}
.block-overlap-gallery {
  overflow: hidden;
  width: 625px;
  position: relative;
}
.slidify, .slidify .slidify__elt, .slidify .slidify__elt img { height: 100%}

