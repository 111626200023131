.section-huge-menu {
  position: fixed;
  min-height: 100%;
  width: 100%;
  top: 0;
  right: -100%;
  z-index: 100;
  transition: right 250ms ease-in, background 250ms linear 200ms;
  background: white;
/*
  &:after {
    content: '';
    display: block;
    background: #3C4A54;
    opacity: 0.1;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 101;
    top: 0;
  }*/
}
.section-huge-menu .container {
  z-index: 102;
  position: relative;
}
.huge-menu__row-logo {
  margin-top: 100px;
  margin-bottom: 100px;
}
.section-enterprise-header {
  position: relative;
}
.section-huge-menu .footer-menu .footer-menu-link {
  transition: color 100ms linear;
}
.section-huge-menu .footer-menu .footer-menu-link:hover {
  color: $color__red;
}
.section-huge-menu .footer-menu,
.section-huge-menu .footer-menu .footer-title,
.section-huge-menu .footer-menu .footer-menu-link {
  color: $color__dark-gray;
}
.section-huge-menu .footer-menu,
.section-huge-menu .footer-menu .footer-title,
.section-huge-menu .footer-menu .footer-menu-link,
.section-huge-menu .footer-menu .footer-menu-link:hover {
  letter-spacing: 0.5px;
}
.section-huge-menu .footer-menu .footer-title:after {
  border-bottom-color: $color__dark-gray;
}
.section-huge-menu .footer-menu .footer-title {
  opacity: 1;
}

//.section-huge-menu { display:  none;}
// MENU IS CLOSED
body {
  background: white;
}
.section-huge-menu {
  transition: right 300ms ease-in, background 300ms linear 300ms;
  background: url('../images/huge-menu-image.jpg');
  background-size: cover;
}
/*.section-hero, .wrapper, .page-footer, .landing__header__logo, .section-landing {
  opacity: 1;
}*/
  // MENU IS OPEN
.section-huge-menu.active {
  right: 0;
  //background: rgba(89, 102, 111, 0.1);
}
.menu-open {
  position: static;
  //overflow-y: scroll;
  background-size: cover;
  background-attachment: fixed;

  .section-huge-menu { display:  block; position: absolute}

  /*.landing__header__logo, .section-landing, section-hero, .wrapper, .page-footer {
    visibility: hidden;
  }*/
}

.huge-menu-social {
  z-index:999;
  position: absolute;
  right:0;
  top:0;

  .footer__ico-fb, .footer__ico-map { display: block;}
}