.section-hero {
  position: relative;
}
.hero__logo {
  background: url('../images/logo-white.svg');
  width: 80px;
  height: 75px;
  display: block;
  margin-bottom: 43px;
}

.hero__h1 {
  color: #fff;
  font-family: "acumin-pro-extra-condensed";;
  font-size: 100px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 80px;
  margin-bottom: 40px;
}

.hero__p {
  color: #fff;
  font-family: "adelle";
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 30px;
}

.hero .container {
  z-index: 100;
  position: relative;
}

.hero {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
  margin-bottom: 10px;
  background-position: center center;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    opacity: 0.8;
  }
}

.hero--red:after {
  content: '';
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
}
.hero.hero--red:after {
  background-color: $color__red;
  opacity: 0.5;
}
