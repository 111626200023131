.title-large {
  font-family: adelle;
  font-size: 70px;
  line-height: 70px;
  text-align: center;
  margin-bottom: 95px;
  margin-top: 130px;
}

.title-regular.title-underlined:after {
  margin-top: 25px;
}

.title-underlined:after {
  margin-top: 35px;
  display: block;
  content: '';
  border-bottom: 4px solid white;
  text-transform: uppercase;
  width: 30px;
}

.title-underlined--red:after {
  border-color: $color__red;
}
.title-underlined--green:after {
  border-color: $color__green;
}
.title-underlined--dark:after {
  border-color: $color__dark-gray;
}

.title-underlined--white:after {
   border-color: white;
 }
.title-underlined--purple:after {
  border-color: $color__purple;
}
.title-underlined--center:after {
  margin-left: calc(50% - 15px);
}
.title-underlined.title-small:after {
  border-bottom: 3px solid white;
}
.title-med {
  text-transform: uppercase;
  font-size: 60px;
  letter-spacing: -1px;
  font-weight: 600;
  font-family: acumin-pro-extra-condensed;
  margin-bottom: 30px;
  line-height: 50px;
}

.title-xlarge {
  font-family: acumin-pro-extra-condensed;
  font-size: 120px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 95px;
}
.title-regular {
  font-family: acumin-pro-extra-condensed;
  font-size: 40px;
  font-weight: 600;
  line-height: 35px;
}
.title-small {
  font-family: acumin-pro-extra-condensed;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 20px;
}

.title-spaced {
  font-family: acumin-pro-extra-condensed;
  text-transform: uppercase;
  font-size: 35px;
  letter-spacing: 10px;
  font-weight: 600;
}