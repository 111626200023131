.ta-center { text-align: center}
.ta-right { text-align: right}

.c-red { color: $color__red;}
.c-dark { color: $color__dark-gray;}
.c-purple { color: $color__purple;}
.c-white { color: white;}
.c-gray { color: $color__light-gray;}
.c-green { color: $color__green;}

.bc-red { background-color: $color__red;}
.bc-gray { background-color: #EAEAEA;} //#EDEEEF
.bc-purple { background-color: $color__purple;}
.bc-rose { background-color: #F1E2FA}

.ff-alt { font-family: acumin-pro-extra-condensed; }
.ff-main { font-family: adelle; }

.d-ib { display: inline-block; }
.d-b { display: block; }
.ta-c { text-align: center; }
.p-r { position: relative; }

.mb-a { margin-bottom: 40px;}
.mb-pre-footer { margin-bottom: 110px;}
.mt-a { margin-top: 40px;}
.mt-b { margin-top: 100px;}
.mt-c { margin-top: 6px;}
.ml-a { margin-left: 20px}

.hide { display: none; }
.of-yh {
  overflow-y: hidden;
}

.zi-d {
  z-index: 2;
  position: relative;
}