// animation is slow so you can see it, but probably should be .25 or .15
$thetransition: all .5s cubic-bezier(1,.25,0,.75) 0s;

.btn {
  display: inline-block;
  background: white;
  color: $color__dark-gray;
  margin-bottom: 10px;
  font-family: "acumin-pro-extra-condensed";
  font-size: 22px;
  font-weight: 400;
}
// Default look
.btn {
  cursor: pointer;
  position: relative;
  padding: 18px 60px 18px 20px;
  text-transform: uppercase;
  letter-spacing: 1px;

  line-height: 14px;

  &:hover, &:active, &:focus {
    text-decoration: none;
  }
}

// Rounded look
.btn--round {
  border-radius: 100px;
}


// Colors
.btn--white {
  background: white;
  color: $color__dark-gray;

  &:hover, &:active, &:focus {
    text-decoration: none;
    color: $color__dark-gray;
  }
}

.btn--white-red {
  background: white;
  color: $color__red;

  &:hover, &:active, &:focus {
    text-decoration: none;
    color: $color__red;
  }
}

.btn--white-purple {
  background: white;
  color: $color__purple;

  &:hover, &:active, &:focus {
    text-decoration: none;
    color: $color__purple;
  }

}
.btn--white-green {
  background: white;
  color: #7ED321;

  &:hover, &:active, &:focus {
    text-decoration: none;
    color: #7ED321;
  }
}
.btn--purple {
  background: $color__purple;
  color: white;
  &:hover, &:active, &:focus {
    color: white;
  }
}

.btn--gray {
  background: #C2C5C8;
  color: $color__dark-gray;

  &:hover, &:active, &:focus {
    text-decoration: none;
    color: $color__dark-gray;
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: $color__red;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: $thetransition;
    transition: $thetransition;
  }
  &:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.btn--red {
  background: $color__red;
  color: white;

  &:hover, &:active, &:focus {
    text-decoration: none;
    color: white;
  }
}
.btn--dark {
  background: $color__dark-gray;
  color: white;

  &:hover, &:active, &:focus {
    text-decoration: none;
    color: white;
  }
}


// Button Arrow
.btn:after {
  transition: right 250ms ease-out;
  right: 20px;
  content: '';
  background-repeat: no-repeat;
  width: 19px;
  height: 16px;
  position: absolute;
  display: block;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.btn--yellow:after {
  background-image: url('../images/btn-arrow-yellow.svg');
}
.btn--white:after {
  background-image: url('../images/btn-arrow-dark.svg');
  color: $color__dark-gray;
  &:hover, &:active, &:focus {
    color: $color__dark-gray;
  }
}
.btn--white-purple:after {
  background-image: url('../images/btn-arrow-purple.svg');
}
.btn--white-red:after {
  background-image: url('../images/btn-arrow-red.svg');
  color: $color__red;
  &:hover, &:active, &:focus {
    color: $color__red;
  }
}
.btn--purple:after, .btn--red:after, .btn--dark:after {
  background-image: url('../images/btn-arrow-white.svg');
}
.btn--white-green:after {
  background-image: url('../images/btn-arrow-green.svg');
}
.btn--download:after {
  background: url('../images/btn-download.svg');
  width: 18px;
  height: 18px;
}
.btn--download-red:after {
  background: url('../images/btn-download-red.svg');
  width: 18px;
  height: 18px;
}
.btn--download-white:after {
  background: url('../images/btn-download-white.svg');
  width: 18px;
  height: 18px;
}
.btn--gmap:after {
  background-image: url('../images/btn-gmap.svg');
  width: 23px;
  height: 23px;
  //top: 15px;
}
.btn:hover {
  &:after {
    right: 10px;
  }
}

.btn-gallery {
  cursor: pointer;
  border: 4px solid white;
  color: white;
  padding: 10px 22px;
  padding-right: 80px;
  position: relative;
  font-family: acumin-pro-extra-condensed;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 26px;
  z-index: 2;

  &:hover, &:active, &:focus {
    color: white;
    text-decoration: none;
    span img{
      transform: scale(1.1);
    }
  }

  span {
    height: 100%;
    width: 65px;
    background: white;
    position: absolute;
    right: 0;
    top: 0;
    img {
      transition: all 150ms ease-in;
      margin-top: 15px;
    }
  }
}
