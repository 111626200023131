
.menu__container {
  display: flex;
  margin-left: -5px; // Remove initial margin of item
  margin-bottom: 5px;
}

.menu__item {
  flex: 1;
  display: flex;
  position: relative;
  width: 285px;
  height: 155px;
  margin-left: 5px;
  align-items: center;

  font-family: acumin-pro-extra-condensed;
  font-size: 33px;
  font-weight: 400;
  line-height: 30px;
  color: $color__dark-gray;
  text-decoration: none;

  // Hover overlay
  &:after {
    transition: opacity 50ms ease-out;
    opacity: 0;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background: rgba(227,60,89, 0.73)
  }
}

.menu__item:focus {
  color: $color__dark-gray;
  text-decoration: none;
}

.menu__item.active, .menu__item:hover{
  position: relative;
  color: $color__dark-gray;
  text-decoration: none;

  &:after {
    opacity: 1;
  }

  .menu__item-content {
    color: white;
    margin-top: -20px;
  }

  .menu__item-content:after {
    opacity: 1;
    bottom: -35px;
  }
}

.menu__item-image__img:after {
  background-color: rgba(60,74,84,0.25);
}
.menu__item-image__img {
  background-size: cover;

  background-blend-mode: overlay;

  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left: 0;
  z-index: 1;
  opacity: 0.25;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    opacity: 0.8;
  }
}

.menu__item-content {
  transition: all 200ms;
  z-index: 2;
  display: block;
  position: relative;
  text-align: center;
  width: 100%;
  opacity: 0.9;
  padding: 0 20% 0 20%;
  text-transform: uppercase;
  font-weight: 600;
}

.menu__item-content:after {
  transition: all 200ms;
  opacity: 0;
  bottom: -200px;
  background: url('../images/ico-arrow-bottom.svg');
  width: 19px;
  height: 25px;
  position: absolute;
  content: '';
  display: block;

  margin-top: 10px;
  left: calc(50% - 8px);
}
