// HEADER LEFT: Sections
.header-left {
  font-family: "acumin-pro-extra-condensed";
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.header-left__nav {
  display: flex;
}
.header-left__item {
  transition: 100ms all ease-out;
  position: relative;
  text-align: center;
  width: 210px;
  text-transform: uppercase;
  font-size: 23px;
  font-weight: 400;
  letter-spacing: 1.5px;
  background: white;
  line-height: 50px;
  cursor: pointer;
  color: $color__dark-gray;

  &:hover, &:active {
    color: $color__dark-gray;
    text-decoration: none;
  }
}

.header-left__nav--event .header-left__item--event,
.header-left__nav--enterprise .header-left__item--enterprise{
  &:after {
    transition: 100ms all ease-out;
    content: '';
    display: block;
    position: absolute;
    bottom: -12px;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid white;
    left: 20px;
  }
}

// ENTREPRISE modifier
.header-left__nav--enterprise {
  .header-left__item--enterprise {
    background: white;
    color: $color__dark-gray;
    &:hover {
      background: $color__dark-gray;
      color: white;
      &:after {
        border-top-color: $color__dark-gray;
      }
    }
  }
  .header-left__item--event {
    background: $color__purple;
    color: white;

    &:hover {
      background: white;
      color: $color__purple;
    }
  }
}

// EVENT modifier
.header-left__nav--event {
  .header-left__item--enterprise {
    background: $color__dark-gray;
    color: white;
    &:hover {
      background: white;
      color: $color__dark-gray;
      &:after {
        border-top-color: white;
      }
    }
  }
  .header-left__item--event {
    color: $color__purple;
    background: white;
    &:hover {
      color: white;
      background: $color__purple;
      &:after {
        border-top-color: $color__purple;
      }
    }
  }
}

// Common modifier
.header-left__nav--common {
  .header-left__item--enterprise {
    background: $color__dark-gray;
    color: white;
    &:hover {
      background: white;
      color: $color__dark-gray;
      &:after {
        border-top-color: white;
      }
    }
  }
  .header-left__item--event {
    background: $color__purple;
    color: white;

    &:hover {
      background: white;
      color: $color__purple;
    }
  }
}


// HEADER RIGHT: Language & burger
.header-right {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  font-family: "acumin-pro-extra-condensed";
}
.header-right__item {
  text-align: center;
  margin-right: 1px;
  width: 60px;
  height: 50px;
  color: $color__red;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 27px;
  font-weight: 400;
  line-height: 25px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: all 100ms;
  &:hover, &:active, &:focus {
    color: $color__dark-gray;
    text-decoration: none;

  }

}
.header-right__item.active {
  background: rgba(89,102,111,0.2);
  color: white;

  &:hover {
    background: white;
    color: $color__red;
  }
}
.header-right__item--menu {
  color: white;
  margin: 0;
  background: $color__red;
}

// Burger menu
#nav-icon4 {
  width: 33px;
  height: 25px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon4 span {
  display: block;
  position: absolute;
  height: 6px;
  width: 100%;
  background: white;
  border-radius: 6px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}
* {
  margin: 0;
  padding: 0;
}

#nav-icon4 {
}

#nav-icon4 span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
  top: 9px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
  top: 18px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -3px;
  left: 4px;
}

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 20px;
  left: 4px;
}

.scroll {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  bottom: 50px;
  //background: url('../images/ico-scroll.svg');
  width: 21px;
  height: 40px;
}

nav {
  position: relative;
  z-index: 200;
}


///

* {
  margin: 0;
  padding: 0;
}

// Sticky
.sticky-menu {
  z-index: 998;
  position: fixed;
  background: white;
  width: 100%;
  top: -100px;
  transition: top 400ms ease-in;
}
.sticky-menu nav {
  background-image: linear-gradient(-90deg, #ED5565 0%, #FF9883 48%, #E33C59 100%);
  height: 50px;
}
.sticky-menu.active {
  top: 0;
  .menu__item-content:after { display: none;}
}

.header__logo {
  display: none;
}
.sticky-menu .header-left__item:after {display: none;}
.sticky-menu .menu__item { margin-top: 5px;height: 70px; font-size: 25px; line-height: 23px;}
.sticky-menu .menu__item:hover .menu__item-content { margin-top: -10px;}
.sticky-menu .menu__item:hover .menu__item-content:after {display: none;}


.sticky-menu nav {
    z-index: 998;
}
.sticky-menu menu {
    background: white;
    transition: top 400ms ease-in, 1000ms;
    position: absolute;
    width: 100%;
    top: -500px;
    z-index: 996;
}
.sticky-menu.active menu {
    top: 50px;
}
/* Icon 1 */
