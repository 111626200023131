@import "../general";

html, body, .page-wrapper--landing, .section-landing, .landing__container, .landing__section {
  height: 100%;
}

.landing__header {
  display: block;
  position: absolute;
  z-index: 200;
  width: 100%;
}
.landing__header__logo {
  background: url('../images/logo.png');
  width: 139px;
  height: 129px;
  margin-top: 40px;
  margin-left: 15px;
  display: block;
  float: left;
}
.landing__container {
  display: flex
}
.landing__section {
  flex: 1;
  display: flex;
  position: relative;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.landing__section__content {
  display: block;
  margin: auto;
  z-index: 10;
  position: relative;
  width: 100%;
  text-align: center;
}
.landing__section .overlay {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
  opacity: 0.6;
}
.landing__section--enterprise {
  background-blend-mode: multiply;
  color: $color__dark-gray;
}

.landing__section--event {
  background-blend-mode: multiply;
  color: white;
}
.landing__section__head {
  text-transform: uppercase;
  font-family: "acumin-pro-extra-condensed";
  font-size: 35px;
  font-weight: bold;
  letter-spacing: 5px;
  line-height: 100px;
  text-align: center;
  color: #fff;
}
.landing__section__title {
  opacity: 0.95;
  font-family: "acumin-pro-extra-condensed";
  font-size: 120px;
  font-weight: bold;
  line-height: 100px;
  text-align: center;
  color: #fff;
}
.landing__section__actions {
  margin-top: 250px;
}


//
//.section-huge-menu { position: relative}
.landing__header { top: 0;}
//

@media(max-width:1200px) {
  .landing__header { position: absolute; height: 50px;}
  .section-landing { padding-top: 50px;}
  .landing__header__logo {
    float:none;
    position: absolute;
    top:0;
    left: 0;
    margin-top: 0px;
    width: 40px;
    height: 40px;
    background-size: cover;
    background: url("../images/logo-color.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    top: 7px;
  }
  .landing__section__content {
  }
  .landing__container {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .landing__section {
    width: 100%;
    height: calc((100%) / 2);
  }
  .landing__section__title {
    font-size: 60px;
    line-height: 60px;
  }
  .landing__section__actions {
    margin-top: 60px;
  }
  .landing__section__head {
    font-size: 24px;
    line-height: 30px;
  }
}


